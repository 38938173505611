<template>
    <div class="px-8 mt-4">
      <v-dialog
          v-model="prViewItemDialog"
          persistent
          fullscreen
          hide-overlay
          transition="dialog-bottom-transition"
          class="prModal"
          scrollable
        >
          <v-card>
            <v-toolbar
              dark
              color="green darken-2"
              max-height="64"
            >
              <v-toolbar-title>{{ canvasData.isDBM == 0 ? canvasData.PRCODE : canvasData.PRCODETemp }}</v-toolbar-title>
                <v-spacer></v-spacer>
              <v-btn
                icon
                dark
                @click="clickCloseMainDialog()"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>
          <v-alert
          border="left"
          type="error"
          v-if="canvasData.isCancelled"
          prominent
          dense
          class="white--text amber accent-4"
        >
          <strong>CANCELLED</strong><br />
          <small>Date Cancelled: {{formatDateTime(canvasData.DateCancelled)}}</small>
        </v-alert>

        <!-----Header----->
        <v-card-text style="max-height: 900px;">
            <v-row class="mt-4">
              <v-col cols="12" class="d-flex justify-center">
                 <v-subheader class="font-weight-bold text-h6">Abstract of Quotation</v-subheader>
              </v-col>
            </v-row>
            <v-form @submit.prevent ref="AOQFormRef" class="my-10">
            <v-row class="mr-3">
              <v-col cols="3">
                <v-subheader class="font-weight-bold">Type of Bidding</v-subheader>
              </v-col>
              <v-col cols="3">
                 <span v-if="canvasData.AOQTypeID == 1">Item Bid</span>
                 <span v-if="canvasData.AOQTypeID == 2">Lot Bid</span>
                 <span v-if="!canvasData.AOQTypeID">Not yet selected</span>
              </v-col>

              <v-col cols="3">
                <v-subheader class="font-weight-bold">Date Received from PSU(RFQ)</v-subheader>
              </v-col>
              <v-col cols="3">
              <v-menu
                dense
                ref="menu1"
                v-model="menu1"
                :close-on-content-click="false"
                :return-value.sync="canvasData.DateReceived"
                transition="scale-transition"
                offset-y
                class="mb-4"
                min-width="auto"
                v-if="switchEditMode"
              >
                <template  v-slot:activator="{ on, attrs }">
                  <v-combobox
                    dense
                    v-model="canvasData.DateReceived"
                    label="Date Received"
                    :rules="[formRules.required]"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-combobox>
                </template>
                <v-date-picker
                  v-model="canvasData.DateReceived"
                  no-title
                  scrollable
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    color="primary"
                    @click="menu1 = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.menu1.save(canvasData.DateReceived)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
              <span v-else>
                {{formatDate(canvasData.DateReceived)}}
              </span>
              </v-col>
            </v-row>

            <v-row class="mt-n5 mr-3">
              <v-col cols="3">
                <v-subheader class="font-weight-bold">Mode Of Procurement</v-subheader>
              </v-col>
              <v-col cols="3">
                <v-autocomplete
                  v-model="canvasData.mopID"
                  :items="mop"
                  item-text="mopDescription"
                  item-value="mopID"
                  :rules="[formRules.required]"
                  label="Mode of Procurement"
                  clearable
                  dense
                  v-if="switchEditMode"
                ></v-autocomplete>
                <span v-else>
                {{getMOPDesc(canvasData.mopID)}}
              </span>
              </v-col>

              <v-col cols="3">
                <v-subheader class="font-weight-bold">Closing Date</v-subheader>
              </v-col>
              <v-col cols="3">
              <v-menu
                dense
                ref="menu2"
                v-model="menu2"
                :close-on-content-click="false"
                :return-value.sync="canvasData.ClosingDate"
                transition="scale-transition"
                offset-y
                class="mb-4"
                min-width="auto"
                v-if="switchEditMode"
              >
                <template  v-slot:activator="{ on, attrs }">
                  <v-combobox
                    dense
                    v-model="canvasData.ClosingDate"
                    label="Date Received"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-combobox>
                </template>
                <v-date-picker
                  v-model="canvasData.ClosingDate"
                  no-title
                  scrollable
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    color="primary"
                    @click="menu2 = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.menu2.save(canvasData.ClosingDate)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
              <span v-else>
                {{formatDate(canvasData.ClosingDate) || "N/A"}}
              </span>
              </v-col>
            </v-row>
            </v-form>
            <v-row class="mt-n8 mb-4">
              <v-col lg="12" class="py-4 px-7">
                <v-card class="" >
                <v-data-table
                  v-model="selectedAOQItems"
                  :headers="headersAOQ"
                  :items="formData.AOQLists"
                  item-key="ppmpItemID"
                  class="elevation-1 mt-4"
                  show-select
                >
                  <template v-slot:top>
                    <v-toolbar flat>
                        <span class="green--text text--darken-2">Abstract of Quotation</span> 
                      <v-spacer></v-spacer>
                      <v-switch
                        v-model="switchEditMode"
                        v-if="!canvasData.isCancelled"
                        inset
                        class="mr-3"
                        color="header"
                        hide-details
                        label="Edit Mode"
                      ></v-switch>
                      <v-btn
                        :disabled="selectedAOQItems.length == 0"
                        color="green darken-2"
                        @click="recanvass"
                        class="mr-2 white--text"
                        small
                        v-if="!switchEditMode && (canvasData.isUtilized == 0 || ableToRecanvass == true)"
                      >
                        <v-icon>mdi-file-refresh</v-icon>
                        recanvass
                      </v-btn>
                      <v-btn
                      :disabled="formData.AOQLists.length == 0"
                        color="red darken-2"
                        @click="unpublish"
                        class="mr-2 white--text"
                        small
                        v-if="!switchEditMode && canvasData.isUtilized == 0"
                      >
                        <v-icon>delete</v-icon>
                        unpublish
                      </v-btn>
                          <v-btn
                          outlined
                          :disabled="formData.AOQLists.length == 0"
                            color="red darken-2"
                            @click="Generate"
                            class="mr-2"
                            small
                            v-if="!switchEditMode"
                          >
                            <v-icon>print</v-icon>
                            print
                          </v-btn>
                          <v-btn
                            outlined
                            :disabled="formData.AOQLists.length == 0"
                            color="green darken-2"
                            @click="chooseSupplier()"
                            class="mr-2"
                            v-if="switchEditMode"
                            small
                          >
                            <v-icon>mdi-plus</v-icon>
                            Select Suppliers
                          </v-btn>
                          <v-btn
                            color="#E45200"
                            class="white--text"
                            :disabled="AOQSuppliers.length == 0 || enableUpdate(temp_data, AOQFormdata)"
                            @click="update"
                            :loading="isLoading"
                            small
                            v-if="switchEditMode"
                          >
                            <v-icon>mdi-check</v-icon>
                            Update AOQ
                          </v-btn>
                    </v-toolbar>
                  </template>
                <template v-slot:body="{ items }">
                  <tbody v-if="items.length > 0">
                    <tr v-for="(item, i) in items" :key="i">
                      <td>
                        <v-checkbox
                          v-model="selectedAOQItems"
                          :value="item"
                          style="margin: 0px; padding: 0px"
                          color="header"
                          hide-details
                          v-if="(item.IsRecanvassed == 0 && item.PRstatus != 4) || item.Qty_waived > 0"
                        />
                      </td>
                      <td align="center">{{ i + 1 }}</td>
                      <td align="center">{{ formatQty(item.Qty_waived || 0) }}</td>
                      <td align="center">{{ item.Qty }}</td>
                      <td align="end">{{ formatPrice(item.UnitCost) }}</td>
                      <td align="end">{{ formatPrice(item.UnitCost * item.Qty) }}</td>
                      <td align="center">{{ item.UnitMeasureDescription }}</td>
                      <td>{{ item.ItemName + " " + item.PPMPItemDescription }}</td>
                      <td align="center" v-for="(supplier, j) in item.suppliers" :key="j" @click="editAOQ(supplier, item)">
                        <v-badge
                          :color="colors[supplier.Qty * supplier.UnitCost > 0 && supplier.rank <= 2 ? supplier.rank : supplier.Qty * supplier.UnitCost > 0 ? 3 : 4]"
                          :content="supplier.Qty * supplier.UnitCost > 0 ? supplier.rank + 1 : null"
                        >
                        <span :style="supplier.Qty * supplier.UnitCost > item.UnitCost * item.Qty ? 'color:red;' : ''">
                        {{supplier.Qty + ' x ' + formatPrice(supplier.UnitCost) + ' = ' + formatPrice(supplier.Qty * supplier.UnitCost)}}
                        </span>
                        </v-badge>
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr><td :colspan="headersAOQ.length + 1" align="center">No items available</td></tr>
                  </tbody>
                  <tbody v-if="AOQFormdata.AOQTypeID == 2 && AOQSuppliers.length > 0">
                    <tr style="background: lightgrey">
                      <td colspan="6" align="center" class="text-no-wrap">Overall Total: <b>₱ {{formatPrice(getOverAllTotal())}}</b></td>
                      <td align="center" v-for="(supplier, j) in AOQData.Suppliers" :key="j">
                        <b class="text-no-wrap">₱ {{formatPrice(getTotalPerSupplier(supplier))}}</b>
                      </td>
                    </tr>
                  </tbody>
                </template>
                </v-data-table>
                </v-card>
              </v-col>
            </v-row>
            <!-- <v-row class="mt-n8 mb-4" v-if="AOQLists_deleted.length > 0">
              <v-col lg="12" class="py-4 px-7">
                <v-card class="" >
                <v-data-table
                  :headers="headersAOQ_deleted"
                  :items="AOQLists_deleted"
                  item-key="ItemID"
                  class="elevation-1 mt-4"
                >
                  <template v-slot:top>
                    <v-toolbar flat>
                        <span class="red--text text--darken-2">Deleted Suppliers</span> 
                      <v-spacer></v-spacer>
                      <v-btn
                        :disabled="AOQLists_deleted.length == 0"
                        color="red darken-2"
                        @click="unpublish"
                        class="mr-2 white--text"
                        small
                        v-if="switchEditMode"
                      >
                        <v-icon>mdi-restore</v-icon>
                        Restore
                      </v-btn>
                    </v-toolbar>
                  </template>
                <template v-slot:body="{ items }">
                  <tbody v-if="items.length > 0">
                    <tr v-for="(item, i) in items" :key="i">
                      <td align="center">{{ i + 1 }}</td>
                      <td align="center">{{ item.Qty }}</td>
                      <td align="end">{{ formatPrice(item.UnitCost) }}</td>
                      <td align="end">{{ formatPrice(item.UnitCost * item.Qty) }}</td>
                      <td align="center">{{ item.UnitMeasureDescription }}</td>
                      <td>{{ item.ItemName }}</td>
                      <td align="center" v-for="(supplier, j) in item.suppliers" :key="j" @click="editAOQ(supplier, item)">
                        <v-badge
                          :color="colors[supplier.Qty * supplier.UnitCost > 0 && supplier.rank <= 2 ? supplier.rank : supplier.Qty * supplier.UnitCost > 0 ? 3 : 4]"
                          :content="supplier.Qty * supplier.UnitCost > 0 ? supplier.rank + 1 : null"
                        >
                        {{supplier.Qty + ' x ' + formatPrice(supplier.UnitCost) + ' = ' + formatPrice(supplier.Qty * supplier.UnitCost)}}
                        </v-badge>
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr><td :colspan="headersAOQ.length + 1" align="center">No items available</td></tr>
                  </tbody>
                  <tbody v-if="AOQFormdata.AOQTypeID == 2 && AOQSuppliers.length > 0">
                    <tr style="background: lightgrey">
                      <td colspan="6" align="center" class="text-no-wrap">Overall Total: <b>₱ {{formatPrice(getOverAllTotal())}}</b></td>
                      <td align="center" v-for="(supplier, j) in AOQData.Suppliers" :key="j">
                        <b class="text-no-wrap">₱ {{formatPrice(getTotalPerSupplier(supplier))}}</b>
                      </td>
                    </tr>
                  </tbody>
                </template>
                </v-data-table>
                </v-card>
              </v-col>
            </v-row> -->
        </v-card-text>
          </v-card>
        </v-dialog>
      
    <v-dialog v-model="addSupplierDialog" persistent max-width="500px">
      <v-card>
        <v-card-title>
          <span class="subtitle-1 font-weight-bold"> Select Suppliers</span>
          <v-spacer></v-spacer>
          <v-btn icon dark color="secondary2" @click="addSupplierDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-form @submit.prevent ref="formRef2">
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-autocomplete
                    v-model="AOQData.Suppliers"
                    :items="suppliers"
                    color="header"
                    item-text="CompanyName"
                    item-value="SupplierID"
                    chips
                    label="Suppliers"
                    multiple
                    deletable-chips
                  >
                  </v-autocomplete>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-form>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="addSupplierDialog = false"> Cancel </v-btn>
          <v-btn
            color="green darken-1"
            class="white--text"
            @click="saveSuppliers"
            :loading="isLoading"
          >
            <v-icon>mdi-check-circle</v-icon>
            save
          </v-btn>
          <!-- <v-btn
            v-else
            color="green darken-1"
            text
            @click="update"
            :loading="isLoading"
          >
            Update
          </v-btn> -->
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="editSupplierDialog" persistent max-width="450px">
      <v-card>
        <v-card-title>
          <span class="subtitle-1 font-weight-bold"> Edit Quotation</span>
          <v-spacer></v-spacer>
          <v-btn icon dark color="secondary2" @click="editSupplierDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
          <v-card-text>
            <v-container>
              <v-row no-gutters>
                <v-col cols="12">
                  <v-card
                    class="pa-3"
                    color="grey lighten-3"
                    flat
                  >
                  <v-row no-gutters v-if="currentSupplier">
                    <v-col cols="12" sm="3">
                      <strong>Supplier: </strong>
                    </v-col>
                    <v-col cols="12" sm="9">
                      {{getSupplierName(currentSupplier.SupplierID)}}
                    </v-col>
                  </v-row>
                  <v-row no-gutters v-if="currentItem">
                    <v-col cols="12" sm="3">
                      <strong>Item: </strong>
                    </v-col>
                    <v-col cols="12" sm="9">
                      {{currentItem.ItemName}} <br /> <i>{{currentItem.itemDescription}}</i>
                    </v-col>
                  </v-row>
                  <v-row no-gutters v-if="currentItem">
                    <v-col cols="12" sm="3">
                      <strong>Amount: </strong>
                    </v-col>
                    <v-col cols="12" sm="9">
                      {{formatPrice(currentItem.Qty * currentItem.UnitCost)}}
                    </v-col>
                  </v-row>
                  </v-card>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="12">
                  <v-form @submit.prevent ref="formDataEdit">
                    <v-row no-gutters>
                      <v-col cols="12">
                          <v-textarea
                            v-model="AOQFormdata.supplierItemDescription"
                            class="ml-sm-1"
                            color="header"
                            rows="1"
                            auto-grow
                            :readonly="!editItemDesc"
                            :append-icon="editItemDesc ? 'mdi-close-circle' : 'mdi-pencil-box'"
                            @click:append="editItemDesc = !editItemDesc"
                            label="Item Description">
                          </v-textarea>
                      </v-col>
                      <v-col cols="12">
                      <v-switch
                        v-model="switchEditQuotation"
                        inset
                        color="header"
                        class="mb-4"
                        hide-details
                        label="Edit Qty & UnitCost"
                      ></v-switch>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-text-field 
                          v-model="AOQFormdata.supplierQty" 
                          class="mr-sm-1"
                          color="header"
                          @keyup="computeQuotation()"
                          :rules="[formRules.noNegativeNumberAllowed, formRules.numberRequiredZeroAllowed]"
                          :disabled="!switchEditQuotation"
                          label="Quantity">
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-text-field
                          v-model="AOQFormdata.supplierUnitCost"
                          class="ml-sm-1"
                          color="header"
                          @keyup="computeQuotation()"
                          :rules="[formRules.noNegativeNumberAllowed, formRules.numberRequiredZeroAllowed]"
                          :disabled="!switchEditQuotation"
                          label="Unit Cost">
                        </v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          v-model="AOQFormdata.supplierAmount"
                          @keyup="computeAmount()"
                          color="header"
                          :rules="[formRules.noNegativeNumberAllowed, formRules.numberRequiredZeroAllowed]"
                          label="Total Amount">
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" outlined @click="editSupplierDialog = false">
          Cancel
          </v-btn>
          <v-btn color="grey darken-1" outlined @click="reset()">
          Reset
          </v-btn>
          <v-btn
            color="green darken-1"
            @click="updateAOQ"
            :loading="isLoading"
            class="white--text"
          >
          <v-icon>mdi-update</v-icon>
            Update
          </v-btn>
          <!-- <v-btn
            v-else
            color="green darken-1"
            text
            @click="update"
            :loading="isLoading"
          >
            Update
          </v-btn> -->
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="unpublishDialog" persistent max-width="450px">
      <v-card>
        <v-card-title>
          <span class="subheader font-weight-bold">Confirmation</span>
          <v-spacer></v-spacer>
          <v-btn icon dark color="secondary2" @click="unpublishDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
          <v-card-text>
            Are you sure you want to unpublish this AOQ?
          </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" outlined @click="unpublishDialog = false">
          Cancel
          </v-btn>
          <v-btn
            color="red darken-3"
            :loading="isLoading"
            class="white--text"
            @click="confirmUnpublish()"
          >
          <v-icon>delete</v-icon>
            Unpublish
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="recanvassDialog" scrollable persistent max-width="1000px">
      <v-card>
        <v-card-title>
          <span class="subheader font-weight-bold">Confirmation</span>
          <v-spacer></v-spacer>
          <v-btn icon dark color="secondary2" @click="recanvassDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
          <v-card-text style="max-height: 900px">
            Are you sure you want to recanvass this AOQ?
            <br /><br />
            <i><b>NOTE:</b> Please review the items carefully before clicking the "RECANVASS" button. This action is irreversible.</i>
            <br /><br />
            <v-data-table
                  :headers="headersAOQ_temp"
                  :items="selectedAOQItems"
                  item-key="ppmpItemID"
                  class="elevation-1 mt-4"
                >
                  <template v-slot:top>
                    <v-toolbar flat>
                        <span class="green--text text--darken-2">Items Selected</span> 
                      <v-spacer></v-spacer>
                    </v-toolbar>
                  </template>
                <template v-slot:body="{ items }">
                  <tbody v-if="items.length > 0">
                    <tr v-for="(item, i) in items" :key="'sai' + i">
                      <td align="center">{{ i + 1 }}</td>
                      <td align="center">{{ item.Qty }}</td>
                      <td align="end">{{ formatPrice(item.UnitCost) }}</td>
                      <td align="end">{{ formatPrice(item.UnitCost * item.Qty) }}</td>
                      <td align="center">{{ item.UnitMeasureDescription }}</td>
                      <td>{{ item.ItemName + " " + item.PPMPItemDescription }}</td>
                      <td align="center">{{ formatQty(item.Qty_waived) }}</td>
                      <td align="center">{{ formatQty(item.Qty_waived > 0 ? item.Qty_waived : item.Qty) }}</td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr><td :colspan="headersAOQ.length + 1" align="center">No items available</td></tr>
                  </tbody>
                </template>
                </v-data-table>
          </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey darken-1" outlined @click="recanvassDialog = false">
          Cancel
          </v-btn>
          <v-btn
            color="green darken-3"
            :loading="isLoading"
            class="white--text"
            @click="confirmRecanvass()"
          >
          <v-icon>mdi-file-refresh</v-icon>
            Recanvass
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="updateDialog" persistent max-width="450px">
      <v-card>
        <v-card-title>
          <span class="subheader font-weight-bold">Confirmation</span>
          <v-spacer></v-spacer>
          <v-btn icon dark color="secondary2" @click="updateDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
          <v-card-text>
            Are you sure you want to update this AOQ?
            <div class="mt-7">
              <i><strong>Note:</strong> All supplier/s deleted will be permanently removed.</i>
            </div>
          </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" outlined @click="updateDialog = false">
          Cancel
          </v-btn>
          <v-btn
            color="orange darken-3"
            @click="saveAOQ"
            :loading="isLoading"
            class="white--text"
          >
          <v-icon>mdi-check</v-icon>
            Update AOQ
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <fade-away-message-component
      displayType="variation2"
      v-model="fadeAwayMessage.show"
      :message="fadeAwayMessage.message"
      :header="fadeAwayMessage.header"
      :top="fadeAwayMessage.top"
      :type="fadeAwayMessage.type"
    ></fade-away-message-component>
    </div>
</template>
    
    <script>
      export default {
	props : {
        canvasData: {},
	},
        data: () => ({
          prDetails: [],
          headersAOQ: [
              {
                text: 'Item No',
                align: 'center',
                sortable: false,
                value: 'ItemNo',
              },
              {
                text: 'Quantity (Waived)',
                align: 'center',
                sortable: false,
                value: 'Qty_waived',
              },
              {
                text: 'Quantity',
                align: 'center',
                sortable: false,
                value: 'Qty',
              },
              {
                text: 'Unit Cost',
                align: 'end',
                sortable: false,
                value: 'UnitCost',
              },
              {
                text: 'Amount',
                align: 'end',
                sortable: false,
                value: 'Amount',
              },
              { text: 'Unit Measure', align: 'center', value: 'UnitMeasureDescription' , sortable: false},
              { text: 'Items', value: 'ItemName ', sortable: false }
            ],
          headersAOQ_temp: [
              {
                text: 'Item No',
                align: 'center',
                sortable: false,
                value: 'ItemNo',
              },
              {
                text: 'Quantity',
                align: 'center',
                sortable: false,
                value: 'Qty',
              },
              {
                text: 'Unit Cost',
                align: 'end',
                sortable: false,
                value: 'UnitCost',
              },
              {
                text: 'Amount',
                align: 'end',
                sortable: false,
                value: 'Amount',
              },
              { text: 'Unit Measure', align: 'center', value: 'UnitMeasureDescription' , sortable: false},
              { text: 'Items', value: 'ItemName ', sortable: false },
              {
                text: 'Quantity (Waived)',
                align: 'center',
                sortable: false,
                value: 'Qty_waived',
              },
              {
                text: 'Quantity (Allowable)',
                align: 'center',
                sortable: false,
                value: 'Qty_allowable',
              },
            ],
          headersAOQ_deleted: [
              {
                text: 'Item No',
                align: 'center',
                sortable: false,
                value: 'ItemNo',
              },
              {
                text: 'Quantity',
                align: 'center',
                sortable: false,
                value: 'Qty',
              },
              {
                text: 'Unit Cost',
                align: 'end',
                sortable: false,
                value: 'UnitCost',
              },
              {
                text: 'Amount',
                align: 'end',
                sortable: false,
                value: 'Amount',
              },
              { text: 'Unit Measure', align: 'center', value: 'UnitMeasureDescription' , sortable: false},
              { text: 'Items', value: 'ItemName', sortable: false }
            ],
          loading: false,
          prViewItemDialog: false,
           footerProps: {'items-per-page-options': [5, 10, 20, 50, 100, 500]},
           options: {},
          isLoading: false,
          formData: {
              Lists: [],
              PRID: null,
              AOQLists: []
            },
          itemList: [],
          totalAmount: 0,
          bidTypeDialog: false,
          addSupplierDialog: false,
          suppliers: [],
          AOQData: {
            AOQTypeID: null,
            Suppliers: [],
            UnitCost: null,
            Qty: null,
            ItemDescription: null
          },
          panel: [],
          items: 5,
          AOQLists: [],
          AOQSuppliers: [],
          editRes: true,
          editSupplierDialog: false,
          currentSupplier: null,
          currentItem: null,
          AOQFormdata: {
            supplierQty: null,
            supplierUnitCost: null,
            supplierAmount: null,
            supplierItemDescription: null,
            mopID: null,
            DateReceived: null,
            ClosingDate: null,
          },
          colors: ['red', 'green', 'blue', 'grey', 'transparent'],
          mop: [],
          menu1: false,
          menu2: false,
          fadeAwayMessage: {
            show: false,
            type: "success",
            header: "Successfully Added!",
            message: "",
            top: 10,
          },
          recanvassDialog: false,
          unpublishDialog: false,
          updateDialog: false,
          switchEditMode: false,
          temp_data: null,
          switchEditQuotation: false,
          PRAmount: null,
          editItemDesc: false,
          AOQLists_temp: [],
          AOQLists_deleted: [],
          AOQSuppliers_deleted: [],
          selectedAOQItems: [],
          ableToRecanvass: false
        }),
       watch: {
          canvasData: {
            handler (data) {
            if(data.PRID){
            if(this.temp_data == null){
              this.temp_data = JSON.stringify(data);
            }
            this.prViewItemDialog = true;
            this.getAOQItems();
            }
            },
            deep: true,
          },
          formData: {
            handler(data) {
              this.totalAmount = 0;
            data.Lists.forEach(item => {
              this.totalAmount += parseFloat(item.Qty) * parseFloat(item.UnitCost)
            });
            },
            deep: true,
          },
          AOQFormdata: {
            handler() {
            },
            deep: true,
          },
        },
        created(){
          this.getAllSuppliers();
          this.getMOP();
        },
        methods: {
        all () {
        this.panel = [...Array(this.items).keys()].map((k, i) => i)
        },
        // Reset the panel
        none () {
          this.panel = []
        },
        checkBalance(){
          let actualAmount = parseFloat(this.currentItem.Qty * this.currentItem.UnitCost)
          let amount = parseFloat(this.AOQFormdata.supplierAmount)
          return actualAmount >= amount || "Insuficient Balance";
        },
        editAOQ(supplier, item){
          if(this.switchEditMode){
            this.currentSupplier = supplier;
            this.currentItem = item;
            let qty = parseFloat(parseFloat(supplier.Qty) || item.Qty)
            let unitCost = parseFloat(parseFloat(supplier.UnitCost) || item.UnitCost)
            this.AOQFormdata.supplierQty = qty.toFixed(2);
            this.AOQFormdata.supplierUnitCost = unitCost.toFixed(2);
            this.AOQFormdata.supplierAmount = (qty * unitCost).toFixed(2)
            this.AOQFormdata.supplierItemDescription = supplier.ItemDescription || ""
            this.PRAmount = (qty * unitCost).toFixed(2)
            this.editSupplierDialog = true;
          }
        },
        updateAOQ(){
          if(this.$refs.formDataEdit.validate()){
            const index = this.formData.AOQLists.indexOf(this.currentItem)
            const data = this.formData.AOQLists[index].suppliers
            // let rank = 0;
            data.forEach(item => {
                if(item.SupplierID == this.currentSupplier.SupplierID){
                  item.UnitCost = parseFloat(this.AOQFormdata.supplierUnitCost);
                  item.Qty = parseFloat(this.AOQFormdata.supplierQty);
                  item.ItemDescription =  this.AOQFormdata.supplierItemDescription;
                }
            });
            data.slice().sort (function(a,b) {
              return (a.Qty * a.UnitCost === 0) - (b.Qty * b.UnitCost === 0) || a.Qty * a.UnitCost - b.Qty * b.UnitCost;
            })
            .forEach (function(d,i) {
                d.rank = i;
            })
            let temp = this.formData.AOQLists;
            this.formData.AOQLists = [];
            this.formData.AOQLists = temp
            this.editSupplierDialog = false;
          }
        },
        changeIput(){
          return this.$refs.formDataEdit.validate()
        },
        Generate() {
          window.open(this.apiUrl + "/pr/aoq/" + this.canvasData.PRID + "/" + this.canvasData.AOQID);
        },
        clickCloseMainDialog () {
          this.formData.Lists = [];
            this.prViewItemDialog = false;
            this.unpublishDialog = false;
            this.recanvassDialog = false;
            this.selectedAOQItems = [];
            this.eventHub.$emit('closeCanvasModal', false);
        },
        chooseSupplier(){
          this.AOQData.Suppliers = this.AOQSuppliers;
          this.addSupplierDialog = true;
        },
        getAOQItems() {
            let data = new FormData();
               data.append('AOQID', this.canvasData.AOQID);
                  this.axiosCall(
                  '/aoq/items',
                  'POST',
                  data
              )
              .then((res) => {
                this.loading = false;
                this.formData.AOQLists = res.data.data.result;
                this.AOQLists_temp = res.data.data.result;
                this.AOQData.Suppliers = res.data.data.suppliers;
                this.AOQSuppliers = this.AOQData.Suppliers;
                this.headersAOQ = [
              {
                text: 'Item No',
                align: 'center',
                sortable: false,
                value: 'ItemNo',
              },
              {
                text: 'Quantity (Waived)',
                align: 'center',
                sortable: false,
                value: 'Qty_waived',
              },
              {
                text: 'Quantity',
                align: 'center',
                sortable: false,
                value: 'Qty',
              },
              {
                text: 'Unit Cost',
                align: 'end',
                sortable: false,
                value: 'UnitCost',
              },
              {
                text: 'Amount',
                align: 'end',
                sortable: false,
                value: 'Amount',
              },
              { text: 'Unit Measure', align: 'center', value: 'UnitMeasureDescription' , sortable: false},
              { text: 'Items', value: 'ItemName', sortable: false }
            ];
                this.AOQSuppliers.forEach(item => {
                  this.headersAOQ.push({ text: this.getSupplierName(item.SupplierID), value: 'SupplierName', align: 'center', sortable: false })
                });
                this.formData.AOQLists.forEach(item => {
                  if((item.IsRecanvassed == 0 && item.PRstatus != 4) || item.Qty_waived > 0){
                    this.ableToRecanvass = true;
                  }
                });
              })
          },
          getAllSuppliers() {
            let data = new FormData();
            data.append("searchText", '');
                  this.axiosCall(
                  '/get/all/supplier',
                  'POST',
                  data
              )
              .then((res) => {
                this.suppliers = res.data.data
              })
          },
          saveSuppliers(){
            if(this.$refs.formRef2.validate()){
              this.AOQLists_temp.forEach(item => {
                if(item.suppliers){
                  let temp = [];
                  item.suppliers.forEach(item2 => {
                    if(!this.AOQData.Suppliers.includes(item2.SupplierID) && item2.AOQItemID){
                    temp.push(item2)
                  }
                });
                if(temp.length > 0){
                  this.AOQLists_deleted.push({...item, suppliers: temp})
                }
                }
              });
              this.AOQSuppliers.forEach(item => {
                if(!this.AOQData.Suppliers.includes(item.SupplierID)){
                  this.AOQSuppliers_deleted.push(item)
                }
              });
              this.AOQSuppliers = this.AOQData.Suppliers
              this.headersAOQ = [{
                text: 'Item No',
                align: 'center',
                sortable: false,
                value: 'ItemNo',
              },
              {
                text: 'Quantity (Waived)',
                align: 'center',
                sortable: false,
                value: 'Qty_waived',
              },
              {
                text: 'Quantity',
                align: 'center',
                sortable: false,
                value: 'Qty',
              },
              {
                text: 'Unit Cost',
                align: 'end',
                sortable: false,
                value: 'UnitCost',
              },
              {
                text: 'Amount',
                align: 'end',
                sortable: false,
                value: 'Amount',
              },
              { text: 'Unit Measure', align: 'center', value: 'UnitMeasureDescription' , sortable: false},
              { text: 'Items', value: 'ItemName', sortable: false }
            ];
            this.headersAOQ_deleted = [{
                text: 'Item No',
                align: 'center',
                sortable: false,
                value: 'ItemNo',
              },
              {
                text: 'Quantity',
                align: 'center',
                sortable: false,
                value: 'Qty',
              },
              {
                text: 'Unit Cost',
                align: 'end',
                sortable: false,
                value: 'UnitCost',
              },
              {
                text: 'Amount',
                align: 'end',
                sortable: false,
                value: 'Amount',
              },
              { text: 'Unit Measure', align: 'center', value: 'UnitMeasureDescription' , sortable: false},
              { text: 'Items', value: 'ItemName', sortable: false }
            ];
              this.AOQSuppliers.forEach(item => {
                this.headersAOQ.push({ text: this.getSupplierName(item), value: 'SupplierName', align: 'center', sortable: false })
              });
               this.AOQSuppliers_deleted.forEach(item => {
                  this.headersAOQ_deleted.push({ text: this.getSupplierName(item.SupplierID), value: 'SupplierName', align: 'center', sortable: false })
                });
              this.formData.AOQLists.forEach(item => {
                let temp = []
                this.AOQSuppliers.forEach((supplier, index) => {
                  if(item.suppliers && item.suppliers[index] && item.suppliers[index].SupplierID == supplier){
                    temp.push({ AOQItemID: item.suppliers[index].AOQItemID || null, SupplierID: supplier, ItemDescription: item.suppliers[index].ItemDescription || null, Qty: item.suppliers[index].Qty || 0, UnitCost: item.suppliers[index].UnitCost || 0, rank: item.suppliers[index].rank || null  })
                  }
                  else{
                  temp.push({ SupplierID: supplier, ItemDescription: null, Qty: 0, UnitCost: 0  })
                    
                  }
                });
                item.suppliers = temp
              });
              this.addSupplierDialog = false
            }
          },
          reset(){
            this.AOQFormdata.supplierQty = 0;
            this.AOQFormdata.supplierUnitCost = 0;
            this.AOQFormdata.supplierAmount = 0;
          },
          getMOP(){
            this.axiosCall("/get/all/POUtil", "POST", []).then((res) => {
              this.mop = res.data.data.mop;
            });
          },
          getMOPDesc(id){
            let res = "";
            this.mop.forEach(item => {
              if(item.mopID == id){
                res = item.mopDescription;
              }
            });
            return res;
          },
          enableUpdate(data, new_data){
            return data == JSON.stringify(new_data)
          },
          saveAOQ(){
          if(this.$refs.AOQFormRef.validate()){
            this.isLoading = true;
            let data = new FormData();
            data.append("AOQID", this.canvasData.AOQID);
            data.append("AOQTypeID", this.canvasData.AOQTypeID);
            data.append("mopID", this.canvasData.mopID);
            data.append("DateReceived", this.canvasData.DateReceived);
            data.append("ClosingDate", this.canvasData.ClosingDate);
            data.append("List", JSON.stringify(this.formData.AOQLists));
            data.append("ListToDelete", JSON.stringify(this.AOQLists_deleted));
            this.axiosCall("/aoq/update", "POST", data).then((res) => {
              this.isLoading = false;
              if (res.status == 200) {
                this.fadeAwayMessage.show = true;
                this.fadeAwayMessage.type = "success";
                this.fadeAwayMessage.message = "AOQ Successfully updated!";
                this.fadeAwayMessage.header = "System Message";
                this.addSupplierDialog = false;
                this.updateDialog = false;
                this.AOQLists_deleted = []
                this.initialize();
              } else {
                this.fadeAwayMessage.show = true;
                this.fadeAwayMessage.type = "error";
                this.fadeAwayMessage.message = "Something went wrong!";
                this.fadeAwayMessage.header = "System Message";
              }
            });
          }
          },
          getSupplierName(item){
            let res = '';
            this.suppliers.forEach(el => {
              if(el.SupplierID ==  item){
                res = el.CompanyName
              }
            });
          return res;

          },
          unpublish(){
            this.unpublishDialog = true
          },
          confirmUnpublish(){
            let data = new FormData();
            data.append('AOQID', this.canvasData.AOQID);
            this.axiosCall("/aoq/unpublish", "POST", data).then((res) => {
              if (res.status) {
                this.fadeAwayMessage.show = true;
                this.fadeAwayMessage.type = "success";
                this.fadeAwayMessage.message = "AOQ Successfully unpublished!";
                this.fadeAwayMessage.header = "System Message";
                this.clickCloseMainDialog();
              } else {
                this.fadeAwayMessage.show = true;
                this.fadeAwayMessage.type = "error";
                this.fadeAwayMessage.message = res.data.message;
                this.fadeAwayMessage.header = "System Message";
              }
            });
          },
          recanvass(){
            let items = [];
            this.selectedAOQItems.forEach(item => {
              if((item.IsRecanvassed == 0 && item.PRstatus != 4) || item.Qty_waived > 0){
                items.push(item)
              }
            });
            this.selectedAOQItems = items;
            this.recanvassDialog = true
          },
          confirmRecanvass(){
            let data = new FormData();
            let items = [];
            this.selectedAOQItems.forEach(item => {
                items.push({
                  ppmpItemID: item.ppmpItemID,
                  Qty: item.Qty_waived > 0 ? item.Qty_waived : item.Qty,
                  WaiveItemIDs: item.WaiveItemIDs,
                  })
            });
            data.append('AOQID', this.canvasData.AOQID);
            data.append('PRID', this.canvasData.PRID);
            data.append('items', JSON.stringify(items));
            this.axiosCall("/aoq/recanvass", "POST", data).then((res) => {
              if (res.status) {
                this.fadeAwayMessage.show = true;
                this.fadeAwayMessage.type = "success";
                this.fadeAwayMessage.message = "AOQ Successfully set for RECANVASS!";
                this.fadeAwayMessage.header = "System Message";
                this.clickCloseMainDialog();
              } else {
                this.fadeAwayMessage.show = true;
                this.fadeAwayMessage.type = "error";
                this.fadeAwayMessage.message = res.data.message;
                this.fadeAwayMessage.header = "System Message";
              }
            });
          },
          update(){
            this.updateDialog = true;
          },
          computeAmount(){
            let amount = this.AOQFormdata.supplierAmount
            let qty = this.AOQFormdata.supplierQty
            this.AOQFormdata.supplierUnitCost = (amount / qty).toFixed(2)
          },
          computeQuotation(){
            let unitCost = this.AOQFormdata.supplierUnitCost
            let qty = this.AOQFormdata.supplierQty
            this.AOQFormdata.supplierAmount = (unitCost * qty).toFixed(2)
          },
          getOverAllTotal(){
            let total = 0;
            this.formData.AOQLists.forEach(item => {
              total = total + (parseFloat(item.Qty) * parseFloat(item.UnitCost))
            });
            return total.toFixed(2)
          },
          getTotalPerSupplier(supplier){
            let total = 0;
            this.formData.AOQLists.forEach(item => {
              if(item.suppliers){
                item.suppliers.forEach(item2 => {
                  if(supplier.SupplierID == item2.SupplierID){
                    total = total + (parseFloat(item2.Qty) * parseFloat(item2.UnitCost))
                  }
                });
              }
            });
            return total.toFixed(2)
          }
        }
      }
    </script>
        
    <style scoped>
    .v-dialog--fullscreen, .prModal {
      overflow-x: hidden !important;
    }
    </style>